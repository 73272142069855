import React from 'react';
import { mergePrismicPreviewData } from 'gatsby-source-prismic';
import { Image, Layout, Link } from 'components';
import './blog_post.scss';

const IS_BROWSER = typeof window !== 'undefined';

export const BlogPost = ({ location, pageContext }) => {
  const { data: staticData, siteName } = pageContext;
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__;
  const data = mergePrismicPreviewData({ staticData, previewData });
  const { post } = data;
  const { data: postData } = post;
  const { meta_title: metaTitle, meta_description: metaDesc, title, featured, date, content, excerpt } = postData;
  const seo = {
    title: metaTitle.text || `${title.text} | Yordar`,
    desc: metaDesc.text || excerpt.text,
  };
  return (
    <Layout location={location} seo={seo} bodyClassName="blog-post-body" siteName={siteName}>
      <section className="blog-post">
        <div className="wrapper">
          <header className="blog-post-header">
            <div className="blog-post-content-wrapper">
              <h1 className="blog-post-title" dangerouslySetInnerHTML={{ __html: title.text }} />
              <div className="blog-post-meta">
                <Link className="blog-post-back-link" to="/blog/">
                  Back to blog
                </Link>
                {date && <span className="blog-post-date">Posted {new Date(date).toLocaleDateString('en-AU')}</span>}
              </div>
            </div>
          </header>
          <div className="blog-post-image">
            <Image image={featured} alt={title.text} />
          </div>
          <div className="blog-post-content">
            {excerpt && excerpt.html && (
              <div
                className="blog-post-content-wrapper blog-post-excerpt"
                dangerouslySetInnerHTML={{ __html: excerpt.html }}
              />
            )}
            <div className="blog-post-content-wrapper" dangerouslySetInnerHTML={{ __html: content.html }} />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default BlogPost;
